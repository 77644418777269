<template>
  <div class="integral-page-view">
    <div class="integral-mall-top-group">
      <div class="top-group-wrap">
        <div class="integral-card">
          <img
            class="icon-integral"
            src="~@/assets/img/Points mall-icon-integral-big 32.png"
          />
          <div class="integral-box">
            <div class="integral-label">
              {{ lang_data == 1 ? "当前积分" : "The current integral" }}
            </div>
            <div class="integral-value">
              {{ info ? info.doctor_info.integral : 0 }}
            </div>
          </div>
          <el-button
            size="mini"
            round
            type="primary"
            @click="$router.push('/detailsCredits')"
          >
            {{ lang_data == 1 ? "明细" : "Detail" }}
          </el-button>
        </div>
        <el-button
          type="primary"
          round
          @click="$router.push('/recordExchange')"
        >
          {{ lang_data == 1 ? "兑换记录" : "For record" }}
          <i class="el-icon-arrow-right"></i>
        </el-button>
      </div>
    </div>
    <div class="tabs">
      <el-radio-group
        v-model="tabPosition"
        style="margin-bottom: 20px"
        @change="tabsChange"
      >
        <el-radio-button
          :label="lang_data == 1 ? '全部' : 'All'"
        ></el-radio-button>
        <el-radio-button
          v-for="item in categoryList"
          :key="item.name"
          :label="item.id"
          >{{ item.name }}</el-radio-button
        >
      </el-radio-group>
    </div>
    <div
      class="main-cont"
      v-loading="loading"
      :element-loading-text="lang_data == 1 ? '拼命加载中' : 'Loading'"
    >
      <div class="goods-list-title">
        {{ lang_data == 1 ? "好物兑换" : "Commodity Exchange" }}
      </div>
      <div class="goods-list">
        <div class="goods-item" v-for="item in dataList" :key="item.id">
          <div class="item">
            <div class="goods-banner">
              <img
                v-if="item.main_image_url"
                :src="item.main_image_url"
                alt=""
              />
            </div>
            <div class="goods-title">
              {{ item.name }}
            </div>
            <div class="goods-price-group">
              <div class="goods-current-price">
                <img
                  src="~@/assets/img/Points mall-icon-integral-big 32.png"
                  alt=""
                />
                <span style="margin-left: 5px">{{ item.integral }}</span>
              </div>
              <div class="goods-old-price">
                ￥{{ item.reference_price || 0 }}
              </div>
            </div>
            <el-button
              type="primary"
              round
              @click="goGoodsDetail(item)"
              size="mini"
              style="width: 90%"
              class="mb10 mt10"
            >
              {{ lang_data == 1 ? "立即兑换" : "Commodity Exchange" }}
            </el-button>
          </div>
        </div>
      </div>
      <div
        v-if="dataList.length < 1 && !loading"
        style="color: #999; text-align: center"
      >
        <el-empty
          :description="lang_data == 1 ? '暂无商品' : 'No goods'"
        ></el-empty>
      </div>
      <div
        class="page"
        style="float: right; margin-bottom: 30px"
        v-if="dataList.length > 0"
      >
        <el-pagination
          background
          layout="total,prev, pager, next"
          @current-change="changePage"
          :page-size="page.pageSize"
          :current-page="page.currentPage"
          :total="page.totalPage"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "integralMall",
  data() {
    return {
      lang_data: localStorage.lang_data || 1,
      info: null,
      dataList: [],
      page: {
        currentPage: 1,
        totalPage: 0,
        pageSize: 10,
      },
      isNoMore: false,
      tabPosition: localStorage.lang_data == 1 ? "全部" : "All",
      categoryList: [],
      id: "",
      loading: false,
    };
  },
  mounted() {
    this.getUserInfo();
    this.getCategory();
    this.getData(1);
  },
  methods: {
    changePage(val) {
      this.getData(val);
    },
    tabsChange(val) {
      if (val == (this.lang_data == 1 ? "全部" : "All")) {
        this.id = null;
      } else {
        this.id = val;
      }

      this.getData(1);
    },
    getCategory() {
      this.$axios
        .post("/ucenter/integral_goods/getCategoryList")
        .then((res) => {
          if (res.code == 1) {
            this.categoryList = res.data.list;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    getUserInfo() {
      this.$axios.get("/ucenter/getinfo", {}).then((res) => {
        if (res.code == 1) {
          this.info = res.data;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    getData(current_page) {
      this.loading = true;
      this.$axios
        .get("/ucenter/integral_goods/getList", {
          params: {
            page_size: this.page.pageSize,
            current_page: current_page ? current_page : this.page.currentPage,
            goods_category_id: this.id,
          },
        })
        .then((res) => {
          if (res.code == 1) {
            this.loading = false;
            this.dataList = res.data.list;
            this.page.currentPage = res.data.current_page;
            this.page.totalPage = res.data.total_count;
            if (this.page.currentPage == this.page.totalPage) {
              this.isNoMore = true;
            }
          } else {
            this.$message.error(res.msg);
          }
        });
    },
    goGoodsDetail(item) {
      this.$router.push({
        path: "/exchange",
        query: {
          goods_id: item.id,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.integral-page-view {
  width: 100%;
  height: calc(100% - 70px);
  display: flex;
  flex-direction: column;
  overflow: auto;
  .integral-mall-top-group {
    width: 100%;
    height: 100px;
    max-width: 1920px;
    background: url("~@/assets/img/Points mall-bg-home.png") no-repeat;
    background-size: 1920px 146px;
    background-position: top center;
    overflow: hidden;
    flex: none;
    .top-group-wrap {
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .integral-card {
        text-align: center;
        width: 238px;
        height: 100px;
        background: url("~@/assets/img/Points mall-bg-integralcard.png")
          no-repeat 0px 20px;
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 20px 0;
        .icon-integral {
          width: 32px;
          height: 32px;
          margin-right: 21px;
        }
        .integral-box {
          flex: 1;
          text-align: left;
          .integral-label {
            font-size: 12px;
          }
          .integral-value {
            font-size: 24px;
            font-weight: bold;
            color: #009a83;
          }
        }
      }
    }
  }
  .tabs {
    width: 1200px;
    margin: 20px auto 0;
  }
  .main-cont {
    width: 1200px;
    flex: 1;
    margin: 0 auto;
    .goods-list-title {
      margin-bottom: 10px;
      font-size: 20px;
      font-weight: bold;
      color: #333;
      line-height: 28px;
      position: relative;
      padding-left: 10px;
      &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 4px;
        height: 12px;
        background: #009a83;
        border-radius: 2px;
      }
    }
    .goods-list {
      width: 100%;
      overflow: hidden;
      justify-content: space-between;
      min-height: 100px;
      .goods-item {
        width: 20%;
        box-sizing: border-box;
        padding: 10px 24px 20px;
        float: left;
        .item:hover {
          box-shadow: 0px 0px 12px 4px #e4e4e4;
          border: 1px solid #e4e4e4;
        }
        .item {
          background: #ffffff;
          // box-shadow: 0px 2px 8px 4px #e6e6e6;
          overflow: hidden;
          border: 1px solid #e6e6e6;
          display: flex;
          flex-direction: column;
          align-items: center;
          border-radius: 8px;
          .goods-banner {
            width: 100%;
            height: 210px;
            background: #eeeeee;
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
          .goods-title {
            margin-top: 10px;
            height: 42px;
            line-height: 20px;
            padding: 0 12px;
            font-weight: 600;
          }
          .goods-price-group {
            width: 100%;
            padding: 0 12px;
            margin-top: 10px;
            display: flex;
            align-content: center;
            justify-content: space-between;
            box-sizing: border-box;
            .goods-current-price {
              font-size: 16px;
              font-weight: bold;
              color: #009a83;
              display: flex;
              align-items: center;
              flex-shrink: 0;
              img {
                width: 24px;
              }
            }
            .goods-old-price {
              font-size: 12px;
              color: #b0b1b5;
              text-decoration: line-through;
            }
          }
        }
      }
    }
  }
}
</style>